@import './general';

/*CSS Novo - março/23 */

button:focus {
  outline: none;
}


.section-login-filial {
  display: flex;
  background-color: #f1eee9;
  height: 93vh;
  font-family: 'Frutiger';
}

.left-column,
.right-column {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;


  justify-content: center;
  align-items: center;
}
.left-column {
  width: 50%;
}
.right-column {
  width: 50%;
  align-items: center;
}
.left-column {
  width: 50%;
}
.right-column {
  width: 50%;
}

@media only screen and (max-width: 1024px) {
  .section-login-filial {
    flex-direction: column;
  }
  .left-column,
  .right-column {
    width: auto;
    height: 50%;
  }

}
/* LOGOS ------------------------------------------ */


@media only screen and (max-width: 1024px) {
  .section-login-filial {
    flex-direction: column;
  }
  .left-column,
  .right-column {
    width: auto;
    height: 50%;
  }

}

.logo_inicio {
  transform: translate(0px) !important;
}

.logo_bici {
  background-color: #009AD9;;
  padding: 25px 65px;
  position: absolute;
  bottom: 20px;
  left: 0;
  transform: translate(-230px);
  -webkit-transition: 0.8s ease-in-out;
  -moz-transition: 0.8s ease-in-out;
  -o-transition: 0.8s ease-in-out;
  transition: 0.8s ease-in-out;
  border: none;
}

.logo_fish {
  background-color: #009AD9;;
  padding: 25px 65px;
  position: absolute;
  bottom: 20px;
  right: 0;
  transform: translate(230px);
  -webkit-transition: 0.8s ease-in-out;
  -moz-transition: 0.8s ease-in-out;
  -o-transition: 0.8s ease-in-out;
  transition: 0.8s ease-in-out;
  border: none;
}

.left-column:hover .logo_bici {
  transform: translate(0px);
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  -webkit-box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2);
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2);
}

.right-column:hover .logo_fish {
  transform: translate(0px);
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  -webkit-box-shadow: 0 8px 8px 0 rgba(0,0,0,0.3);
  box-shadow: 0 8px 8px 0 rgba(0,0,0,0.3);
}
.left-column .bg_left,.right-column .bg_right {
    -webkit-transition: 0.6s ease-in-out;
    -moz-transition: 0.6s ease-in-out;
    -o-transition: 0.6s ease-in-out;
    transition: 0.6s ease-in-out;
}

.left-column:hover .bg_left{
    -webkit-transition: 0.6s ease-in-out;
    -moz-transition: 0.6s ease-in-out;
    -o-transition: 0.6s ease-in-out;
    transition: 0.6s ease-in-out;
    transform: scale(1.1);
}

.right-column:hover .bg_right{
    -webkit-transition: 0.4s ease-in-out;
    -moz-transition: 0.4s ease-in-out;
    -o-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    transform: scale(1.1);
}


.bg_left,.bg_right{
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    background-color: black;
}

.bg_left{
     background-image: url('../img/bg_bici.jpg');

}

.bg_right{
    background-image: url("../img/bg_fish.jpg");

}

.bg_left::before,.bg_right::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(52, 73, 94, 0);
  transition: 1s ease-in-out;

}

.left-column:hover .bg_left:before,.left-column:hover .bg_left:before,
.right-column:hover .bg_right:before,.right-column:hover .bg_right:before {
  transition: 0.6s ease-in-out;
  background-color: rgba(28, 38, 48, 0.7); 
}

.span-filial{
  font-family: 'Frutiger';
  font-size: calc(96.8vw / 15);
  color: rgba(255,255,255,0);
  top: 30%;
  left: 30%;
  position: absolute;
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  border: none;
}

.left-column:hover span{    
  color: rgba(255,255,255,1);
  -webkit-transition: 0.7s ease-in-out;
  -moz-transition: 0.7s ease-in-out;
  -o-transition: 0.7s ease-in-out;
  transition: 0.7s ease-in-out;
}
.right-column:hover span{    
  color: rgba(255,255,255,1);
  -webkit-transition: 0.7s ease-in-out;
  -moz-transition: 0.7s ease-in-out;
  -o-transition: 0.7s ease-in-out;
  transition: 0.7s ease-in-out;

}

.container-topo {
  display: flex;
  width: 100%;
  height: 7vh;
  color: #009AD9;
  font-family: 'Frutiger';
  align-self: center;

}

.btn-corporativo,
.btn-ssc {
  font-size: inherit;
  display: flex;
  margin-right: 7%;
}

.btn-corporativo {
  margin-top: 1%;
}

.btn-ssc img {
  width: 100% !important;
  padding-left: 10%;
}

.btn-login {
  align-self: center;
  margin-left: 7%;
  font-size: inherit;
}

.container-topo-rigth {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}