@import './#variables/colors';
@import './#variables/global';
@import './#variables/media-queries.scss';

/* Mixins */
@import './#mixins/border';


/* Extends */
@import './#extends/button-main';


body {
font-family: 'Bebas Neue', cursive;
font-family: 'Fira Sans', sans-serif;
  font-size: 1.6rem;
  overflow: visible !important;
}

.container {
	@include respond-above(lg) {
		width: 90%;
		max-width: 1300px;
		min-width: 1190px - 30;
	}
}

.row {
  width: 100%;
}