
.text_history{
    font-size: 18px;
    text-align: justify;
}

.container h3{
    margin-bottom: 30px;
}

.col-sm-6{
    margin-bottom: 10px;
}

.modal-content{
    width: 100%;
    padding: 0px;
}

.container .row{
    width: 100%;
    margin: 0px;
}

.img-fluid{
    margin: 20px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.oculto{
    display: none;
}

.btn-info{
    margin: 10px;
    height: 50px;
    font-size: 20px;
}