.homolog-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
}

.whatsapp-btn {
    position: fixed;
    left: 10px;
    bottom: 10px;
    width: 3rem;
    height: 3rem;
    background-color: #4DC247;
    color: white;
    text-align: center;
    border: none;
    border-radius: 50%;
    padding: 0;
    box-shadow: 2px 2px 6px gray;
    padding-bottom: 4px;
}

.whatsapp-btn-homolog {
    bottom: 50px;
}

.whatsapp-btn svg {
    font-size: 1.75rem;
}

.whatsapp-envia-mensagem {
    position: fixed;
    bottom: 50px;
    left: 75px;
    height: 3rem;
    line-height: 3rem;
    font-size: 1.25rem;
    display: none;
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 0 8px;
    box-shadow: 2px 2px 6px gray;
    background-color: white;
}

.whatsapp-envia-mensagem::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent lightgray transparent transparent;
}

.whatsapp-btn:hover + .whatsapp-envia-mensagem {
    display: block;
}